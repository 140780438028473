<template>
  <div class="result-layout">
    <unknown-info-block
      v-if="segmentationInfo.unknownToken.length > 0"
      :segmentation-info="segmentationInfo"
      @close="closeUnknowInfoBlock"
    />
    <spinner
      v-if="isLoading"
      :title="$t('resultDescription.analysisProcessing')"
      class="layout-spinner"
      size="50"
    />
    <model-result
      v-else-if="isModelResult"
    />
    <component
      v-else
      :is="layout || 'EmptyResult'"
      :data-result-id="currentResultId"
      :data-frame-id="currentQuestionDataFrameId"
      :result-info="resultInfo"
      :restrictions="restrictInfo"
      :segmentation-payload="segmentationPayload"
      :transcript="transcript"
      :intent="intent"
      :is-war-room-addable="isWarRoomAddable"
      :is-histogram-bin-setting="isHistogramBinSetting"
      :model-predict-info="modelPredictInfo"
      :data-column-map="dataColumnMap"
      mode="display"
      @fetch-new-components-list="getComponent"
    />
  </div>
</template>

<script>
import UnknownInfoBlock from '@/components/resultBoard/UnknownInfoBlock'
import ModelResult from './components/ModelResult'
import { mapState, mapActions, mapMutations } from 'vuex'
import { isEnOrEnum, intentType } from '@/utils/general'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

export default defineComponent({
  name: 'ResultDisplay',
  components: {
    UnknownInfoBlock,
    ModelResult
  },
  setup () {
    const {
      askQuestion,
      askResult,
      getComponentList,
      askCondition,
      selectedColumnList,
      filterRestrictionList,
      currentQuestionId,
      currentQuestionInfo,
      setCurrentQuestionDataFrameId,
      setCurrentQuestionInfo,
      setCurrentQuestionId,
      getHistoryQuestionList
    } = useAskingModuleContext()

    return {
      askQuestion,
      askResult,
      getComponentList,
      askCondition,
      selectedColumnList,
      filterRestrictionList,
      currentQuestionId,
      currentQuestionInfo,
      setCurrentQuestionDataFrameId,
      setCurrentQuestionInfo,
      setCurrentQuestionId,
      getHistoryQuestionList
    }
  },
  data () {
    return {
      isLoading: true,
      layout: null,
      resultInfo: null,
      restrictInfo: [],
      timeStamp: this.$route.query.stamp,
      timeoutFunction: null,
      segmentationInfo: {
        question: null,
        unknownToken: [],
        nlpToken: []
      },
      segmentationPayload: null,
      transcript: null,
      // 目前兩版 transcript 過渡期先放這
      isWarRoomAddable: false,
      isHistogramBinSetting: false,
      currentQuestionDataFrameId: null,
      dataColumnMap: null,
      totalSec: 50,
      periodSec: 200,
      intent: null,
      intentType,
      modelPredictInfo: null
    }
  },
  computed: {
    ...mapState('result', ['currentResultId', 'isModelResult']),
    ...mapState('dataSource', ['dataSourceId', 'dataFrameId', 'algoConfig']),
    questionStructure () {
      let structure = ''
      this.segmentationPayload.sentence.forEach((item, index, array) => {
        structure += index === array.length - 1 ? `${item.word}(${item.type})` : `${item.word}(${item.type}) + `
      })
      return structure
    }
  },
  watch: {
    '$route.query' ({ question, action, stamp }) {
      if (!question) return false
      this.fetchApiAsk({
        question,
        dataSourceId: this.dataSourceId,
        dataFrameId: this.dataFrameId,
        shouldCancelToken: true
      })
    },
    askCondition: {
      deep: true,
      handler (newValue, oldValue) {
        if (
          // 切換 dataframe 被清空時不重新問問題
          newValue.columnList === null && newValue.filterList.length === 0 ||
          // 開啟進階設定取得欄位資料時也不重新問問題
          oldValue.columnList === null && (oldValue.filterList.length === newValue.filterList.length) ||
          this.segmentationPayload === null
        ) return

        // 預先觸發重新計算 column summary 和 column correlation
        // this.triggerColumnDataCalculation()
        this.fetchApiAsk({
          question: this.segmentationPayload.sentence.reduce((acc, cur, index) => {
            let currentWord = cur.word
            return acc + ((index !== 0 && isEnOrEnum(currentWord)) ? ` ${currentWord}` : currentWord)
          }, ''),
          dataSourceId: this.$route.query.dataSourceId,
          dataFrameId: this.$route.query.dataFrameId,
          shouldCancelToken: true
        })
      }
    }
  },
  mounted () {
    if (this.isModelResult) {
      setTimeout(() => {
        this.isLoading = false
      }, 2 * 1000)
    }
    this.fetchData()
  },
  destroyed () {
    if (this.timeoutFunction) window.clearTimeout(this.timeoutFunction)
    this.$store.commit('result/updateCurrentResultId', null)
  },
  methods: {
    ...mapActions('gtm', ['trackAskQuestion', 'resetAskQuestionTracking']),
    // ...mapActions('dataSource', ['triggerColumnDataCalculation']),
    ...mapMutations('dataSource', ['setAlgoConfigModelId', 'setActualColumnId']),
    fetchData () {
      const { dataSourceId, dataFrameId, question } = this.$route.query
      if (!question) return
      this.fetchApiAsk({
        dataSourceId,
        dataFrameId,
        question,
        shouldCancelToken: true
      })
    },
    clearLayout () {
      this.layout = null
      this.resultInfo = null
      this.restrictInfo = []
      this.segmentationPayload = null
      this.currentQuestionDataFrameId = null
      this.transcript = null
      this.isWarRoomAddable = false
      this.isHistogramBinSetting = false
      this.intent = null
      this.$store.commit('dataSource/resetAlgoConfig')
      this.$store.commit('dataSource/resetClusteringInfo')
      this.closeUnknowInfoBlock()
    },
    fetchApiAsk (data) {
      this.clearLayout()
      this.isLoading = true
      // 動態變更 title 為了方便前一頁、下一頁變更時可以快速找到
      document.title = `JarviX - Result - ${data.question}`

      if (this.currentQuestionInfo) {
        let algoType = this.camelize(this.currentQuestionInfo.denotation)
        this.askResult({
          algoConfig: this.algoConfig[algoType] || null,
          questionId: this.currentQuestionId,
          segmentation: this.currentQuestionInfo,
          restrictions: this.filterRestrictionList,
          selectedColumnList: this.selectedColumnList,
          displayConfig: {
            histogramBinSize: null,
            sortOrders: []
          },
          isFilter: false
        }).then(res => {
          this.setCurrentQuestionInfo(null)
          this.$store.commit('result/updateCurrentResultId', res.resultId)
          if (res.layout === 'no_answer') {
            this.setEmptyLayout(res)
            return
          }
          this.getComponent(res.resultId)
        }).catch(() => {
          this.isLoading = false
          this.setCurrentQuestionInfo(null)
        })
        return
      }

      const source = this.$route?.query?.source
      this.trackAskQuestion({ question: data.question, source })

      this.askQuestion(data)
        .then(response => {
          this.getHistoryQuestionList(data.dataSourceId)
          this.setCurrentQuestionInfo(null)
          let questionId = response.questionId
          this.setCurrentQuestionId(questionId)

          let segmentationList = response.segmentationList
          if (segmentationList.length === 1) {
            // 介紹資料集的處理
            switch (segmentationList[0].denotation) {
              case 'INTRODUCTION': {
                this.layout = 'PreviewDataSource'
                this.resultInfo = null
                this.isLoading = false
                return false
              }
              case 'NO_ANSWER': {
                let segmentation = segmentationList[0]
                this.layout = 'EmptyResult'
                this.resultInfo = {
                  title: segmentation.errorCategory,
                  description: segmentation.errorMessage
                }
                // 問句沒結果時，蒐集對應的資訊
                this.trackAskQuestion({
                  isAskQuestionSuccess: false,
                  questionStructure: `${segmentation.sentence[0].word}(${segmentation.sentence[0].type})`,
                  diagram: null
                })

                this.isLoading = false
                return false
              }
              case 'MODEL_PREDICTION': {
                let predictToken = segmentationList[0].sentence.find((token) => token.type === 'PREDICTED_COLUMN')
                predictToken && this.setAlgoConfigModelId(predictToken.modelId)
                predictToken && this.setActualColumnId(predictToken.actualColumnId)
                break
              }
            }

            let structure = segmentationList[0].sentence.reduce((acc, cur) => {
              return acc + `${cur.word}(${cur.type}) `
            }, '')
            this.trackAskQuestion({ questionStructure: structure })

            this.askResult({
              questionId,
              algoConfig: this.algoConfig[this.camelize(segmentationList[0].denotation)] || null,
              segmentation: segmentationList[0],
              restrictions: this.filterRestrictionList,
              selectedColumnList: this.selectedColumnList,
              displayConfig: {
                histogramBinSize: null,
                sortOrders: []
              },
              isFilter: false
            }).then(res => {
              this.$store.commit('result/updateCurrentResultId', res.resultId)
              if (res.layout === 'no_answer') {
                this.setEmptyLayout(res)
                // askResult沒結果，蒐集對應的資料
                this.trackAskQuestion({
                  isAskQuestionSuccess: false,
                  questionStructure: `${segmentationList[0].sentence[0].word}(${segmentationList[0].sentence[0].type})`,
                  diagram: null
                })

                return
              }
              this.getComponent(res.resultId)
            }).catch((error) => {
              if (error.message !== 'cancel') this.isLoading = false
              // askResult失敗
              this.trackAskQuestion({
                isAskQuestionSuccess: false
              })
            })
          } else {
            // 多個結果
            this.layout = 'MultiResult'
            this.resultInfo = { ...response, question: data.question }
            this.isLoading = false
          }
        }).catch((error) => {
          // 解決重新問問題，前一次請求被取消時，保持 loading 狀態
          if (error.message !== 'cancel') this.isLoading = false

          this.setCurrentQuestionInfo(null)
          // askQuestion失敗，清空 params
          this.trackAskQuestion({
            isAskQuestionSuccess: false
          })
        })
    },
    setEmptyLayout (res) {
      this.layout = 'EmptyResult'
      this.resultInfo = {
        title: res.noAnswerTitle,
        description: res.noAnswerDescription
      }
      this.isLoading = false
    },
    getComponent (resultId) {
      window.clearTimeout(this.timeoutFunction)
      this.getComponentList({ resultId })
        .then(componentResponse => {
          switch (componentResponse.status) {
            case 'Process':
            case 'Ready':
              this.timeoutFunction = window.setTimeout(() => {
                this.getComponent(resultId)
              }, this.totalSec)

              // request 間隔最多三秒
              this.totalSec = this.totalSec + this.periodSec > 3000 ? 3000 : this.totalSec + this.periodSec
              this.periodSec = this.totalSec
              break
            case 'Complete':
              this.totalSec = 50
              this.periodSec = 200
              this.modelPredictInfo = componentResponse.mlParam
              this.resultInfo = componentResponse.componentIds
              this.resultInfo.canDoList = componentResponse.canDoList
              this.resultInfo.canSaveResult = componentResponse.canSaveResult
              this.restrictInfo = componentResponse.restrictions
              this.intent = componentResponse.intent
              this.layout = this.getLayout(componentResponse.layout)
              this.segmentationPayload = componentResponse.segmentationPayload
              this.segmentationAnalysis(componentResponse.segmentationPayload)
              this.transcript = componentResponse.transcript
              this.isWarRoomAddable = componentResponse.isWarRoomAddable
              this.isHistogramBinSetting = componentResponse.isHistogramIntervalSetting
              this.currentQuestionDataFrameId = this.transcript.dataFrame.dataFrameId
              this.dataColumnMap = componentResponse.dataColumnMap
              this.setCurrentQuestionDataFrameId(this.currentQuestionDataFrameId)
              this.isLoading = false
              // 當有事件要被追蹤時，才指派對應的track function 蒐集 params
              this.trackAskQuestion({
                isAskQuestionSuccess: true
              })

              break
            case 'Disable':
            case 'Delete':
            case 'Warn':
            case 'Fail':
              this.resultInfo = {
                description: componentResponse.errorMessage
              }
              this.layout = 'EmptyResult'
              // getComponent 失敗
              this.trackAskQuestion({
                isAskQuestionSuccess: false
              })
              this.isLoading = false
              break
          }
        }).catch((error) => {
          if (error.message !== 'cancel') {
            this.isLoading = false
            this.resultInfo = null
            // getComponent 失敗
            this.trackAskQuestion({
              isAskQuestionSuccess: false
            })
          }
        })
    },
    segmentationAnalysis (payloadInfo) {
      this.segmentationInfo.unknownToken = payloadInfo.sentence.filter(element => {
        return element.type === 'UNKNOWN'
      })
      this.segmentationInfo.question = this.$route.query.question
    },
    closeUnknowInfoBlock () {
      this.segmentationInfo = {
        question: null,
        unknownToken: []
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.result-layout {
  width: 100%;

  .seciotn-title {
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 26px;
    margin-bottom: 10px;
  }

  .recommend-question-list-block {
    margin-top: 64px;

    .block-title {
      font-size: 24px;
      letter-spacing: 0.1em;
      line-height: 40px;
      margin-bottom: 48px;
    }

    .category-title {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 12px;
    }
  }
}
</style>
